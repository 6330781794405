#title{
    text-align: center; text-shadow: black 0.8px 0.8px 12px;
    font-size: 250%;
    color: rgb(255, 255, 255);
    position: absolute;
    top: 30%;
    left: 44%;
    background-size: 300px 500px;
}
#user{
    text-align: center;
    position: absolute;
    top: 37%;
    left: 45%;
}
#user2{
    text-align: center;
    position: absolute;
    top: 46%;
    left: 45%;
}
#user3{
    text-align: center;
    position: absolute;
    top: 55%;
    left: 47.5%;
}
#navbarLOGIN{
    background-color: blueviolet;
}
#tul{
   text-align: center; 
}