.react-chatbot-kit-chat-container {
    border: 1px solid #d6d6d6;
    
  }
  .react-chatbot-kit-chat-message-container {
    background-color: rgb(235, 252, 252);
}
.react-chatbot-kit-chat-header{
    background-color: rgb(218, 242, 252);
    font-weight: bold;
  }
  .react-chatbot-kit-chat-bot-avatar-container {
    background-image: url('./../img/asistente.png');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    
  }
  .bubble{ /* burbuja del chatbot*/
    background-image: url(/src/img/asistente.png);
    background-image: url('./../img/asistente.png');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    padding: 20px;
    cursor: pointer;
    border-color: rgb(7, 61, 105);
  }
 .contBUBBLE{ /* contiene el chatbot*/
  position: absolute;
  bottom: 3%;
  left: 33%;
 }
 
  