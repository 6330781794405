.all{
    position: absolute ;
    z-index: 1;
    width: 100%;
}
.navbarhoreditar{
    background-color: rgb(7, 61, 105);
    
}
.itemsedit{
    color: aliceblue;
    text-decoration: none;
}
.nav-item{
    position: relative;
    left: 350%;
}