.navbarvert {  /* barra de navegación vertical del home*/
    position: relative;
    top: 0;
    left: 0;
    width: 150px;
    height: 100vh;
    
    transition: transform 0.5s ease; /* Agregar transición para animación */
    transform: translateX(0);
    background: linear-gradient(20deg, #348bd1 0.01%, #a8c0db 30%, #a8c0db 65%, #073d69 90% );
  
  }
  .menu{ /* menu que desplega la barra de navegación */
    
    position: relative;
  padding: 30px;
  border-radius: 10px;
  position: absolute;
  left: 160%;
  top: 40px;
  background-color: #073d69;
  }
  .menu::before{
    content: "";
  background-image: url(/src/img/menu.png);
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(99%) sepia(1%) saturate(315%) hue-rotate(188deg) brightness(117%) contrast(100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  }
  .navbar.open { /* determina la posición al abrir la barra de navegación */ 
    transform: translateX(-150px); /* Desplazar la navbar hacia la izquierda */
  }
  
  .navbar-list { /* modifica la lista de elementos del navbar*/
    list-style: none;
    padding: 0;
    position: absolute;
    left: 15%;
  }
  
  .navbar-list li {/* modifica cada elemento de la lista*/
    padding: 15%;
    margin-top: 60%;
    color: white;
    text-align: center;
  }
  
  .toggle-button { /* botón */
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  #sala { /* bontón de la lista */
    background-image: url(/src/img/clubes.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-size: 20px;
    position: relative;
    left: 0%;
    width: 60px;
    height: 80px;
    margin: 10px;
    cursor: pointer;
    border: none;
    
  
}

#sala > * {
    margin-bottom: 0px; /* Agregado un margen inferior entre elementos */
}

#tutor{ /* botón de la lista*/
  background-image:url(/src/img/libreta.png);
  background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-size: 20px;
    position: relative;
    left: 0%;
    width: 60px;
    height: 80px;
    margin: 10px;
    cursor: pointer;

}
#solicitar{ /* botón de la lista*/
  background-image:url(/src/img/usuario.png);
  background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-size: 20px;
    position: relative;
    left: 0%;
    width: 60px;
    height: 80px;
    margin: 10px; 
    cursor: pointer;
}

.button-text {
  position:relative;
  top: 70px; /* Ajusta este valor según sea necesario */
  color: black; /* Hereda el color del enlace */
 
}
.button-link {
  /* Estilos generales del botón */
  padding: 10px 20px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

/* Estilos para el botón activo */
.button-link.active {
   /* Cambia esto al color que desees para el botón activo */
  filter: invert(17%) sepia(16%) saturate(7285%) hue-rotate(187deg) brightness(93%) contrast(95%); /* Cambia el color del texto si es necesario */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
   /* Cambia el color deseado */
   /* Ajusta la opacidad según tus preferencias */
  z-index: -1; /* Colocar la superposición detrás del contenido del botón */
  pointer-events: none;
  
}

/* Otros estilos para el botón inactivo (opcional) */
.button-link:not(.active) {
  background-color: transparent;
  color: rgb(184, 184, 184); /* Cambia el color del texto si es necesario */
}
